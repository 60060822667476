import { render, staticRenderFns } from "./password.vue?vue&type=template&id=d6b8e8b0&scoped=true&"
import script from "./password.vue?vue&type=script&lang=js&"
export * from "./password.vue?vue&type=script&lang=js&"
import style0 from "./password.vue?vue&type=style&index=0&id=d6b8e8b0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/.pnpm/registry.npmmirror.com+vue-loader@15.10.1_cache-loader@4.1.0_css-loader@3.6.0_vue-template-co_ujdr5qzltqyzqfezhwzrbmnany/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6b8e8b0",
  null
  
)

export default component.exports